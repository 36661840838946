<template>
  <div class="write-off">
    <el-tabs :value="activeName">
      <el-tab-pane label="活动申请" name="first">
        <ActivityApply
          v-model="formData.tpmAuditActReqVos"
          :disabled="formRole !== 'update2'"
          @changeAct="changeAct"
          @setFielValue="setFielValue"
        />
      </el-tab-pane>
      <!-- <el-tab-pane label="活动销量" name="second"></el-tab-pane> -->
      <el-tab-pane label="核销申请" name="third">
        <div class="form-item">
          <el-row :gutter="10">
            <el-col :span="12" class="flex">
              <div class="label-box">核销申请名称</div>
              <el-input
                v-model="formData.auditName"
                placeholder="请填写核销申请名称"
                :disabled="formRole !== 'update2'"
              ></el-input>
            </el-col>
            <el-col :span="12" class="flex">
              <div class="label-box">核销单号</div>
              <el-input
                v-model="formData.auditCode"
                placeholder="核销单号"
                disabled
              ></el-input>
            </el-col>
             <el-col :span="12" class="flex">
              <div class="label-box">核销类型</div>
              <!-- <el-input
                v-model="formData.ext4Name"
                placeholder="核销类型"
                :disabled="formConfig.code === 'view'"
              ></el-input> -->
              <el-select
                style="flex:1;"
                :disabled="formRole !== 'update2'"
                v-model="formData.ext4"
                @change="getWriteOffList($event)"
                placeholder="请选择核销类型"
              >
                <el-option
                  v-for="item in writeOffList"
                  :key="item.dictCode"
                  :label="item.dictValue"
                  :value="item.dictCode"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" class="flex">
              <div class="label-box">备注</div>
              <el-input
                type="textarea"
                v-model="formData.remarks"
                placeholder="请填写备注"
                rows="3"
                :disabled="formRole !== 'update2'"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <WriteOffDetails
          v-model="formData.detailVos"
          :getFieldValue="getFieldValue"
          @setFielValue="setFielValue"
          @changeTasting="changeTasting"
          @changeGift="changeGift"
          :code="formConfig.code"
          :formRole="formRole"
          :disabled="formRole !== 'update2'"
          :tastingReqVos="formData.tastingReqVos"
          :giftRespVos="formData.giftRespVos"
          :invoiceRespVos="formData.invoiceRespVos"
          :auditId="formData.id"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="dialog-footer" v-if="!noButton">
      <!-- <el-button
        type="primary"
        icon="el-icon-clocheckse"
        :loading="$store.state.buttonApiIsLoading"
        @click="submit(3)"
        v-if="formConfig.code !== 'view'"
        >一次性核销</el-button
      > -->
      <el-button
        type="primary"
        icon="el-icon-check"
        :loading="$store.state.buttonApiIsLoading"
        @click="submit(1)"
        v-if="formConfig.code !== 'view'"
        >保存</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-clocheckse"
        :loading="$store.state.buttonApiIsLoading"
        @click="submit(2)"
        v-if="formConfig.code !== 'view'"
        >保存并提交</el-button
      >
      <el-button
        icon="el-icon-close"
        :loading="$store.state.buttonApiIsLoading"
        type="danger"
        @click="closeModal"
        >关闭</el-button
      >
    </div>
  </div>
</template>

<script>
import ActivityApply from '../components/activity/apply.vue';
import WriteOffDetails from '../components/write_off_details/details.vue';
import request from '../../../../../../utils/request';

export default {
  name: 'write-off',
  props: {
    formConfig: Object,
    noButton: Boolean,
  },
  data() {
    return {
      formRole: '',
      activeName: 'first',
      formData: {
        tpmAuditActReqVos: [],
        auditName: '',
        remarks: '',
        detailVos: [],
        auditFileRespVos: [],
        tastingReqVos: [],
        giftRespVos: [],
        punchReqVos: [],
      },
      writeOffList: [],
    };
  },
  components: {
    ActivityApply,
    WriteOffDetails,
  },
  mounted() {
    if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
      request
        .get('/tpm/tpmAuditController/query', { id: this.formConfig.row.id })
        .then((res) => {
          const data = res.result;
          data.tastingReqVos = data.tastingRespVos;
          data.punchReqVos = data.punchRespVos.map((v) => {
            const obj = v;
            obj.positionCode = obj.userPosCode;
            return obj;
          });
          data.detailVos = data.detailVos.map((v) => ({
            ...v,
            levelOptions: v.productLevelCode
              ? [
                {
                  productLevelCode: v.productLevelCode,
                  productLevelName: v.productLevelName,
                },
              ]
              : [],
            replenishmentProductCodes: (v.replenishmentProductList || []).map(
              (sub) => sub.productCode,
            ),
            replenishmentProductList: v.replenishmentProductList || [],
            productOptions: v.replenishmentProductList || [],

            auditFileRespVos: v.auditFileRespVos.map((m) => {
              let obj = m;
              let ext1 = {};
              // const ext1 = JSON.parse(JSON.parse(JSON.parse(item.ext1).ext1).ext1) ? JSON.parse(JSON.parse(JSON.parse(item.ext1).ext1).ext1) : JSON.parse(JSON.parse(item.ext1).ext1) ? JSON.parse(JSON.parse(item.ext1).ext1) : JSON.parse(item.ext1);
              if (
                !JSON.parse(obj.ext1 || '{}').ext1
                || JSON.parse(obj.ext1 || '{}').ext1 === ''
              ) {
                ext1 = JSON.parse(obj.ext1 || '{}');
              } else if (
                !JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1
                || JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1
                  === ''
              ) {
                ext1 = JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}');
              } else if (
                !JSON.parse(
                  JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1
                    || '{}',
                ).ext1
                || JSON.parse(
                  JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1
                    || '{}',
                ).ext1 === ''
              ) {
                ext1 = JSON.parse(
                  JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1
                    || '{}',
                );
              } else if (
                !JSON.parse(
                  JSON.parse(
                    JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}')
                      .ext1 || '{}',
                  ).ext1 || '{}',
                ).ext1
                || JSON.parse(
                  JSON.parse(
                    JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}')
                      .ext1 || '{}',
                  ).ext1 || '{}',
                ).ext1 === ''
              ) {
                ext1 = JSON.parse(
                  JSON.parse(
                    JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}')
                      .ext1 || '{}',
                  ).ext1 || '{}',
                );
              }
              // if (JSON.parse(JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1 || '{}')) {
              //   ext1 = JSON.parse(JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}').ext1 || '{}');
              // } else if (JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}')) {
              //   ext1 = JSON.parse(JSON.parse(obj.ext1 || '{}').ext1 || '{}');
              // } else {
              //   ext1 = JSON.parse(obj.ext1 || '{}');
              // }
              ext1.ext1 = '';
              obj = { ...obj, ...ext1 };
              return obj;
            }),
          }));
          // if (data.ext4 === 'Y') {
          //   data.ext4Name = '一次性核销';
          // } else {
          //   data.ext4Name = '普通核销';
          // }
          this.formData = data;
        });
    }

    if (this.formConfig && this.formConfig.row) {
      this.formRole = this.formConfig.row.formRole
        ? this.formConfig.row.formRole
        : '';
    }
    if (this.formConfig.code === 'edit' || this.formConfig.code === 'add') {
      this.formRole = 'update2';
    }
  },
  created() {
    this.getWriteOffData();
  },
  methods: {
    // 签批前的提交方法
    async beforeSubmit(code) {
      if (code === 'BTN_004') {
        // 审批通过
        if (this.formRole !== 'update2') {
          return true;
        }
        const formData = { ...this.formData };
        const url = '/tpm/tpmAuditExt/approvingUpdate';
        formData.id = this.formConfig.row.id;
        const res = await request.post(url, formData);
        if (res.success) {
          return true;
        }
        return false;
      }

      return true;
    },
    // 改变了活动
    changeAct() {
      this.formData.detailVos = [];
    },
    // 获取字段数据
    getFieldValue(field) {
      return this.formData[field];
    },
    // 设置字段数据
    setFielValue({ field, val }) {
      if (field === 'detailVos') {
        const _detailVos = this.formData.detailVos.filter(
          (v) => v.actCode !== val.actCode,
        );

        this.formData[field] = val ? _detailVos : [];

        const fineCodes = _detailVos.map((v) => v.fineCode);

        this.formData.auditFileRespVos = val
          ? this.formData.auditFileRespVos.filter((v) => fineCodes.includes(v.fineCode))
          : [];
      } else {
        this.formData[field] = val;
      }
    },
    changeTasting(val) {
      let tastingReqVos = [];
      if (this.formData.tastingReqVos.length > 0) {
        tastingReqVos = val;
      } else {
        tastingReqVos = val;
      }

      this.formData.tastingReqVos = val ? tastingReqVos : [];
    },
    changeGift(val) {
      let giftRespVos = [];
      if (this.formData.giftRespVos.length > 0) {
        giftRespVos = val;
      } else {
        giftRespVos = val;
      }

      this.formData.giftRespVos = val ? giftRespVos : [];
    },
    async getWriteOffList(val) {
      console.log(val);
      this.writeOffList.forEach((cc) => {
        if (cc.dictCode === val) {
          this.formData.ext4Name = cc.dictValue;
          this.formData.ext4 = cc.dictCode;
        }
      });
      console.log(this.detailVos, 666);
      this.$emit('input', this.formData);
    },
    // 获取核销类型数据字典
    getWriteOffData() {
      request.post(
        '/mdm/mdmdictdata/list',
        {
          dictTypeCode: 'hexiaoleixing',
        },
      ).then((res) => {
        if (res.success) {
          this.writeOffList = res.result;
        }
      });
    },
    submit(e) {
      const formData = { ...this.formData };
      console.log(formData, 888);
      const actType = (formData.tpmAuditActReqVos[0] || []).actType || '';
      const { auditName } = formData;
      formData.ext3 = 'project'; // 活动类型, 市场项目活动
      if (actType !== 'promotion') {
        if (formData.detailVos) {
          // let isExt14 = false;
          let isExt15 = false;
          let isExt16 = false;
          // let isExt17 = false;
          formData.detailVos.forEach((v, k) => {
            const obj = v;
            if (obj.ext4) {
              // if (!obj.ext14) {
              //   isExt14 = true;
              // } else
              if (!obj.ext15 && obj.ext2 !== '0021301') {
                isExt15 = true;
              } else if (!obj.ext16 && obj.ext2 !== '0021301') {
                isExt16 = true;
              }
            //  else if (!obj.ext17) {
            //   isExt17 = true;
            // }
            }
          });
          // if (isExt14) {
          //   this.$message.error('活动明细中有收款方名称未填写，请检查！');
          //   return false;
          // }
          if (isExt15) {
            this.$message.error('活动明细中有开户行未填写，请检查！');
            return false;
          }
          if (isExt16) {
            this.$message.error('活动明细中有账号未填写，请检查！');
            return false;
          }
        // if (isExt17) {
        //   this.$message.error('活动明细中有开户行行号未填写，请检查！');
        //   return false;
        // }
        }
      }

      if (!auditName) {
        this.$message.error('请填写核销申请名称');
      } else {
        let url = '/tpm/tpmAuditController/save';
        formData.saveType = e.toString();

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmAuditController/update';
          formData.id = this.formConfig.row.id;
          formData.saveType = (e + 2).toString();
        }
        formData.detailVos.forEach((item) => {
          if (item.auditFileRespVos && item.auditFileRespVos.length > 0) {
            /* eslint-disable */
            item.auditFileRespVos.forEach((au) => {
              au.ext2 = au.totalAmount || au.amount || au.ext2 || 0;
              au.ext1 = JSON.stringify({ ...au, ext1: "" });
              au.ext3 = au.ncross|| 0
            });
          }
          console.log(item.auditFileRespVos, 777);
        });
        if (e === 3) {
          // formData.ext4 = "Y";
          if (this.formConfig.code === "add") {
            formData.saveType = "2";
          } else if (this.formConfig.code === "edit") {
            formData.saveType = "4";
          }
          const { detailVos } = formData;
          // 必填快递单号
          detailVos.forEach((v) => {
            if (!v.ext77) {
              this.$message.error("请填写快递单号");
              return;
            }
          });
          this.$emit("submit", {
            row: formData,
            submitUrl: url,
          });
        }else if (e === 2) {
          const { detailVos } = formData;
          // 必填快递单号
          detailVos.forEach((v) => {
            if (!v.ext77) {
              this.$message.error("请填写快递单号");
              return;
            }
          });
          this.$emit("submit", {
            row: formData,
            submitUrl: url,
          });
        } else {
          request.post(url, formData).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$emit("onClose");
              this.$emit("onGetList");
            }
          });
        }
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang='less' scepod>
.flex {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.label-box {
  flex-shrink: 0;
  margin-right: 10px;
}
</style>
